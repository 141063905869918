/**
 * External Dependencies
 */
import 'jquery';

// Cookie Consent
/* require('./modules/CookieConsent'); */

// Offset html anchor for fixed header
/* require('./modules/anchor-scroll'); */

// Observer an element
/* require('./modules/observer.js'); */

const navBtn = document.querySelector('.mobile-btn');
const body = document.querySelector('body');

navBtn.addEventListener('click', function() {
  this.classList.toggle('change');
  const navBar = document.querySelector('.mobile-menu');

  if (navBar.style.display == 'block') {
    navBar.style.display = 'none';
    body.style.overflow = 'visible';
  } else {
    navBar.style.display = 'block';
    body.style.overflow = 'hidden';
  }
});

const closeNotfallBox = document.querySelector('.notfall-btn-x');
const notfallBtn = document.querySelector('.notfall-btn');
const notfallContainer = document.querySelector('.notfall-hotline');

closeNotfallBox.addEventListener('click', () => {
  notfallContainer.style.display = 'none';
});

notfallBtn.addEventListener('click', () => {
  notfallContainer.style.display = 'block';
});
